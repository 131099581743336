<!-- vehículo generico -->
@if (isVehiculoGenerico) {
  <div class="flex flex-column">
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 156.72 312.87"
    >
      <rect
        x="4.63"
        y="0.5"
        width="147.22"
        height="311.87"
        rx="10.2"
        fill="none"
        stroke="#868686"
        stroke-miterlimit="10"
      />
      <rect
        x="40.68"
        y="47.53"
        width="74.62"
        height="1.73"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <!-- <rect
        #rueda1
        x="0.5"
        y="35.36"
        width="19.98"
        height="61.2"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      /> -->
      <foreignObject
        x="0.5"
        y="35.36"
        width="19.98"
        height="61.2"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      >
        <app-wheel-element
          [rueda]="getRueda(0, 1)"
          [mode]="mode"
          (clickRueda)="
            selectedPosicion = { eje: 0, posicion: 1 };
            clickRueda.emit({ rueda: $event, eje: 0, posicion: 1 })
          "
          [isSelected]="
            (selectedPosicion &&
              selectedPosicion.eje === 0 &&
              selectedPosicion.posicion === 1) ??
            false
          "
        />
      </foreignObject>
      <!-- <rect
        #rueda2
        x="136.24"
        y="35.35"
        width="19.98"
        height="61.2"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      /> -->
      <foreignObject
        x="136.24"
        y="35.35"
        width="19.98"
        height="61.2"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      >
        <app-wheel-element
          [rueda]="getRueda(0, 2)"
          [mode]="mode"
          (clickRueda)="
            selectedPosicion = { eje: 0, posicion: 2 };
            clickRueda.emit({ rueda: $event, eje: 0, posicion: 2 })
          "
          [isSelected]="
            (selectedPosicion &&
              selectedPosicion.eje === 0 &&
              selectedPosicion.posicion === 2) ??
            false
          "
        />
      </foreignObject>
      <rect
        x="74.79"
        y="85.46"
        width="6.41"
        height="24.51"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="42.56"
        y="19.05"
        width="2.6"
        height="72.11"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="67.95"
        y="109.97"
        width="20.23"
        height="17.16"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="65.24"
        y="127.12"
        width="25.64"
        height="4.61"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="61.89"
        y="135.43"
        width="32.76"
        height="6.01"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <line
        x1="88.18"
        y1="121.71"
        x2="67.95"
        y2="121.71"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <polyline
        points="84.7 141.71 84.7 148.02 82.7 148.02 82.7 150.35 73.83 150.35 73.83 147.93 71.83 147.93 71.83 141.71"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <polygon
        points="84.21 203.14 71.08 203.14 71.08 207.43 73.18 207.43 73.18 209.04 71.58 209.04 71.58 216.85 73.28 216.85 73.28 217.95 81.9 217.95 81.9 216.95 84.21 216.95 84.21 209.74 82.8 209.74 82.8 207.54 84.21 207.54 84.21 203.14"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <line
        x1="81.05"
        y1="150.35"
        x2="81.05"
        y2="202.83"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <polyline
        points="81.64 218.23 81.64 303.19 74.93 303.19 74.93 218.23"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <!-- <rect
      #rueda4
        x="135.75"
        y="228.1"
        width="20.46"
        height="60.28"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      /> -->
      <foreignObject
        x="135.75"
        y="228.1"
        width="20.46"
        height="60.28"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      >
        <app-wheel-element
          [rueda]="getRueda(0, 4)"
          [mode]="mode"
          (clickRueda)="
            selectedPosicion = { eje: 0, posicion: 4 };
            clickRueda.emit({ rueda: $event, eje: 0, posicion: 4 })
          "
          [isSelected]="
            (selectedPosicion &&
              selectedPosicion.eje === 0 &&
              selectedPosicion.posicion === 4) ??
            false
          "
        />
      </foreignObject>
      <!-- <rect
        x="0.5"
        y="228.1"
        width="20.46"
        height="60.28"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      /> -->
      <foreignObject
        x="0.5"
        y="228.1"
        width="20.46"
        height="60.28"
        rx="2.53"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      >
        <app-wheel-element
          [rueda]="getRueda(0, 3)"
          [mode]="mode"
          (clickRueda)="
            selectedPosicion = { eje: 0, posicion: 3 };
            clickRueda.emit({ rueda: $event, eje: 0, posicion: 3 })
          "
          [isSelected]="
            (selectedPosicion &&
              selectedPosicion.eje === 0 &&
              selectedPosicion.posicion === 3) ??
            false
          "
        />
      </foreignObject>
      <rect
        x="117.71"
        y="253.39"
        width="18.22"
        height="9.73"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="21.04"
        y="253.39"
        width="17.78"
        height="9.73"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="61.91"
        y="279.6"
        width="32.76"
        height="8.48"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="117.72"
        y="249.59"
        width="6.17"
        height="17.56"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="123.9"
        y="251.59"
        width="3.66"
        height="13.65"
        rx="0.79"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="32.7"
        y="249.58"
        width="6.17"
        height="17.56"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="29.02"
        y="251.59"
        width="3.66"
        height="13.65"
        rx="0.79"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        d="M48,169.13"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="94.66"
        y="64.8"
        width="5.01"
        height="242.71"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="56.89"
        y="64.8"
        width="5.01"
        height="242.71"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <line
        x1="82.68"
        y1="147.95"
        x2="73.84"
        y2="147.95"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="61.91"
        y="175.95"
        width="32.76"
        height="6.83"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="66.19"
        y="205.84"
        width="24.28"
        height="4.7"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="89.56"
        y="169.13"
        width="5.11"
        height="6.21"
        rx="0.93"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="61.92"
        y="169.13"
        width="5.11"
        height="6.21"
        rx="0.93"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="67.03"
        y="170.16"
        width="22.53"
        height="2.58"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="90.24"
        y="205.09"
        width="4.43"
        height="6.21"
        rx="0.93"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="62.59"
        y="205.09"
        width="3.59"
        height="6.21"
        rx="0.93"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="73.64"
        y="253.8"
        width="9.99"
        height="2.95"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <polygon
        points="87.59 267.71 94.67 279.6 61.91 279.6 69.97 267.71 87.59 267.71"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="61.91"
        y="240.31"
        width="32.76"
        height="5.89"
        rx="0.9"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <polygon
        points="75 256.75 75 263.37 69.97 265.22 69.97 267.71 87.59 267.71 87.59 265.01 82.39 262.85 82.39 256.75 75 256.75"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <line
        x1="81.9"
        y1="216.95"
        x2="73.28"
        y2="216.95"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        d="M108.69,168.79"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="110.51"
        y="18.98"
        width="2.6"
        height="72.11"
        fill="#fff"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="26.25"
        y="62.15"
        width="104.71"
        height="7.62"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="121.09"
        y="57.23"
        width="6.17"
        height="17.56"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="127.28"
        y="59.19"
        width="3.66"
        height="13.65"
        rx="0.79"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="74.79"
        y="60.39"
        width="6.41"
        height="25.64"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="38.88"
        y="254.23"
        width="78.72"
        height="8.01"
        fill="#b1b1b1"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <polyline
        points="81.45 150.53 81.45 235.37 75.13 235.37 75.13 150.53"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="52.93"
        y="29.39"
        width="49.27"
        height="33.87"
        rx="2.68"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <rect
        x="55.82"
        y="41.68"
        width="7.11"
        height="17.93"
        rx="1.7"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="63.18"
        y="41.68"
        width="7.11"
        height="17.93"
        rx="1.7"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="70.12"
        y="41.68"
        width="7.11"
        height="17.93"
        rx="1.7"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="77.48"
        y="41.68"
        width="7.11"
        height="17.93"
        rx="1.7"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="84.92"
        y="41.68"
        width="7.11"
        height="17.93"
        rx="1.7"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <rect
        x="92.28"
        y="41.68"
        width="7.11"
        height="17.93"
        rx="1.7"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <path
        d="M52.82,38.8s2.84-3.43,5.28-3.53,7,2.27,15.05,1.1,13.62-6.18,17.34-6.48a23.16,23.16,0,0,1,4.78-.1l4.78.1"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      />
      <circle
        cx="59.18"
        cy="45.17"
        r="0.85"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.25"
      />
      <circle
        cx="66.59"
        cy="45.22"
        r="0.85"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.25"
      />
      <circle
        cx="73.6"
        cy="45.12"
        r="0.85"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.25"
      />
      <circle
        cx="81.01"
        cy="45.17"
        r="0.85"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.25"
      />
      <circle
        cx="88.43"
        cy="44.97"
        r="0.85"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.25"
      />
      <circle
        cx="95.83"
        cy="45.01"
        r="0.85"
        fill="#868686"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.25"
      />
      <rect
        x="71.78"
        y="64.4"
        width="12.42"
        height="6.81"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="130.95"
        y="62.1"
        width="5.2"
        height="7.81"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="67.93"
        y="131.7"
        width="20.23"
        height="3.76"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="29.55"
        y="57.22"
        width="6.59"
        height="17.59"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="26.25"
        y="59.42"
        width="3.3"
        height="13.19"
        rx="0.79"
        fill="#9c9b9b"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <rect
        x="20.76"
        y="62.16"
        width="5.5"
        height="7.69"
        fill="none"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
    </svg>
  </div>
} @else {
  <!-- Cabeza del vehículo -->
  @if (isTractora && ejes[1].tipo !== 'd') {
    <div class="flex flex-column">
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 154.42 218.51"
      >
        <rect
          x="60.48"
          y="41.21"
          width="34.55"
          height="28.27"
          rx="2.44"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <rect
          x="49.85"
          y="11.75"
          width="55.24"
          height="95.8"
          rx="2.02"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="42.95"
          y="11.85"
          width="69.61"
          height="103.35"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <rect
          x="51.33"
          y="91.87"
          width="51.88"
          height="3.17"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="74.28"
          y="70.14"
          width="6.35"
          height="21.7"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="74.24"
          y="95.1"
          width="6.45"
          height="9.67"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="63.53"
          y="104.75"
          width="27.81"
          height="2.8"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="44.07"
          y="59.24"
          width="68.49"
          height="1.6"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="106.78"
          y="33.65"
          width="3.17"
          height="67.18"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="45.56"
          y="32.71"
          width="2.43"
          height="67.18"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="66.68"
          y="117.88"
          width="19.94"
          height="15.98"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="64.44"
          y="133.87"
          width="24.08"
          height="4.29"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.68"
          y="141.14"
          width="30.78"
          height="5.6"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="86.63"
          y1="128.82"
          x2="66.69"
          y2="128.82"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="86.35"
          y1="138.16"
          x2="86.35"
          y2="141.14"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="66.69"
          y1="138.16"
          x2="66.69"
          y2="141.14"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polygon
          points="83.7 204.22 71.47 204.22 71.47 208.29 73.43 208.29 73.43 209.82 71.94 209.82 71.94 217.21 73.52 217.21 73.52 218.26 81.55 218.26 81.55 217.31 83.7 217.31 83.7 210.48 82.39 210.48 82.39 208.4 83.7 208.4 83.7 204.22"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="3.62"
          y="78.03"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="3.62"
          y="72.02"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="149.46"
          y="78.03"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="149.47"
          y="72.01"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="15.77"
          y="166.37"
          width="35.56"
          height="50.3"
          rx="2.14"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M49.83,172.54"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="15.77"
          y="203.38"
          width="8.77"
          height="4.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="15.77"
          y="169.19"
          width="8.77"
          height="4.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="35.05"
          y="144.47"
          width="7.9"
          height="21.91"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="42.95"
          y="144.46"
          width="2.61"
          height="21.91"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="24.54"
          y="170.61"
          width="33.61"
          height="1.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="24.54"
          y="204.82"
          width="33.61"
          height="1.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="82.89 146.77 82.89 152.84 81.02 152.84 81.02 155.07 73.93 155.07 73.93 152.74 72.06 152.74 72.06 146.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="80.63"
          y1="155.19"
          x2="80.63"
          y2="204.33"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="74.31"
          y1="155.19"
          x2="74.31"
          y2="204.33"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="92.19 218.35 92.19 117.23 96.86 117.23 96.86 218.35"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="57.01 218.3 57.01 117.23 61.68 117.23 61.68 218.3"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="81.16"
          y1="152.81"
          x2="74.07"
          y2="152.81"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M131.39,117.81c7.09-.37,8.21-5.22,8.21-5.22V15.73C136.8-1.77,118.7.61,118.7.61h-83s-18.15-2.38-21,15.12v96.86s1.12,4.85,8.21,5.22c6.54.35,47.89.06,54.21,0S124.84,118.16,131.39,117.81Z"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M141.12,22.23a22.34,22.34,0,0,1,6.72,1.68c3.36,1.49,6.53,4.48,6,6.72S149,29,144.32,28.39l-4.66-.56V22.15Z"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M128.92,116.72c6.76-.36,7.83-5,7.83-5V19.33C134.12,2.6,116.81,4.91,116.81,4.91H37.58S20.31,2.6,17.64,19.33v92.41s1.07,4.62,7.84,5c6.24.33,45.69.05,51.72,0S122.67,117.05,128.92,116.72Z"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M128.89,116.58c6.77-.34,7.84-4.67,7.84-4.67V25.36c-2.67-15.68-19.94-13.51-19.94-13.51H37.56S20.29,9.68,17.62,25.36v86.55s1.07,4.33,7.83,4.67c6.25.31,45.69,0,51.72,0S122.65,116.89,128.89,116.58Z"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M13.25,22.23a22.28,22.28,0,0,0-6.71,1.68C3.18,25.4,0,28.39.54,30.63S5.42,29,10.12,28.42l4.67-.56V22.15Z"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M119.65,4.82V7.7s5.91-.87,14.24,4.05"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <polyline
          points="109.2 0.61 109.2 2.85 94.27 2.85 94.27 0.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M35.06,4.82V7.7s-5.94-.87-14.24,4.05"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <polyline
          points="45.55 0.61 45.55 2.85 60.48 2.85 60.48 0.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="102.49"
          y="114.32"
          width="33.68"
          height="30.39"
          rx="1.89"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="21.74"
          y="114.3"
          width="33.68"
          height="30.39"
          rx="1.89"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <line
          x1="24.54"
          y1="144.69"
          x2="24.54"
          y2="116.88"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="61.68"
          y="178.9"
          width="30.53"
          height="6.36"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="65.84"
          y="206.81"
          width="22.38"
          height="4.38"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="87.44"
          y="171.54"
          width="4.76"
          height="5.78"
          rx="0.85"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.68"
          y="171.54"
          width="4.76"
          height="5.78"
          rx="0.85"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="66.44"
          y="173.51"
          width="21"
          height="2.41"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="88"
          y="206.11"
          width="4.13"
          height="5.78"
          rx="0.85"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.73"
          y="206.1"
          width="4.11"
          height="5.78"
          rx="0.85"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="81.42"
          y1="217.36"
          x2="73.65"
          y2="217.36"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="103.82"
          y="166.07"
          width="36.05"
          height="50.3"
          rx="2.14"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M105.3,172.24"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="130.98"
          y="203.07"
          width="8.89"
          height="4.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="130.99"
          y="168.89"
          width="8.89"
          height="4.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="112.69"
          y="145.11"
          width="8.01"
          height="20.96"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="110.04"
          y="145.11"
          width="2.65"
          height="20.96"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="96.91"
          y="170.31"
          width="34.07"
          height="1.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="96.91"
          y="204.52"
          width="34.07"
          height="1.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="102.36"
          y="114.26"
          width="33.68"
          height="30.39"
          rx="1.89"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <line
          x1="132.7"
          y1="144.71"
          x2="132.7"
          y2="117.93"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="103.69"
          y="166.01"
          width="36.05"
          height="50.3"
          rx="2.14"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M105.17,172.18"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="130.85"
          y="203.01"
          width="8.89"
          height="4.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="130.86"
          y="168.83"
          width="8.89"
          height="4.77"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="112.56"
          y="145.05"
          width="8.01"
          height="20.96"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="109.91"
          y="145.05"
          width="2.65"
          height="20.96"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="96.78"
          y="170.25"
          width="34.07"
          height="1.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="96.78"
          y="204.46"
          width="34.07"
          height="1.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <!--   <rect
        #rueda1
        x="6.46"
        y="48.27"
        width="18.62"
        height="57.02"
        rx="2.3"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      /> -->
        <foreignObject
          x="6.46"
          y="48.27"
          width="18.62"
          height="57.02"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        >
          <app-wheel-element
            [rueda]="getRueda(0, 1)"
            [mode]="mode"
            (clickRueda)="
              selectedPosicion = { eje: 0, posicion: 1 };
              clickRueda.emit({ rueda: $event, eje: 0, posicion: 1 })
            "
            [isSelected]="
              (selectedPosicion &&
                selectedPosicion.eje === 0 &&
                selectedPosicion.posicion === 1) ??
              false
            "
          />
        </foreignObject>
        <!-- <rect
        #rueda2
        x="130.28"
        y="48.27"
        width="18.62"
        height="57.02"
        rx="2.3"
        fill="#646463"
        stroke="#1d1d1b"
        stroke-miterlimit="10"
      /> -->
        <foreignObject
          x="130.28"
          y="48.27"
          width="18.62"
          height="57.02"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        >
          <app-wheel-element
            [rueda]="getRueda(0, 2)"
            [mode]="mode"
            (clickRueda)="
              selectedPosicion = { eje: 0, posicion: 2 };
              clickRueda.emit({ rueda: $event, eje: 0, posicion: 2 })
            "
            [isSelected]="
              (selectedPosicion &&
                selectedPosicion.eje === 0 &&
                selectedPosicion.posicion === 2) ??
              false
            "
          />
        </foreignObject>
      </svg>
    </div>
    <!-- cabeza tractora de dos ejes -->
  } @else if (isTractora && ejes[1].tipo === 'd') {
    <div class="flex flex-column">
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 154.42 219.04"
      >
        <path
          d="M104.3,41.87H50.12l3-25.39a5.16,5.16,0,0,1,5.16-5.18H96.13a5.16,5.16,0,0,1,5.17,5.17v0Z"
          fill="none"
          stroke="#707070"
          stroke-miterlimit="10"
        />
        <!-- <rect
      #rueda1
      x="6.46"
      y="27.06"
      width="18.62"
      height="57.02"
      rx="2.3"
      fill="#646463"
      stroke="#1d1d1b"
      stroke-miterlimit="10"
    /> -->
        <foreignObject
          x="6.46"
          y="27.06"
          width="18.62"
          height="57.02"
          rx="2.3"
          stroke-miterlimit="10"
        >
          <app-wheel-element
            [rueda]="getRueda(0, 1)"
            [mode]="mode"
            (clickRueda)="
              selectedPosicion = { eje: 0, posicion: 1 };
              clickRueda.emit({ rueda: $event, eje: 0, posicion: 1 })
            "
            [isSelected]="
              (selectedPosicion &&
                selectedPosicion.eje === 0 &&
                selectedPosicion.posicion === 1) ??
              false
            "
          />
        </foreignObject>
        <!-- <rect
    #rueda2
      x="130.28"
      y="27.06"
      width="18.62"
      height="57.02"
      rx="2.3"
      fill="#646463"
      stroke="#1d1d1b"
      stroke-miterlimit="10"
    /> -->
        <foreignObject
          x="130.28"
          y="27.06"
          width="18.62"
          height="57.02"
          rx="2.3"
          stroke-miterlimit="10"
        >
          <app-wheel-element
            [rueda]="getRueda(0, 2)"
            [mode]="mode"
            (clickRueda)="
              selectedPosicion = { eje: 0, posicion: 2 };
              clickRueda.emit({ rueda: $event, eje: 0, posicion: 2 })
            "
            [isSelected]="
              (selectedPosicion &&
                selectedPosicion.eje === 0 &&
                selectedPosicion.posicion === 2) ??
              false
            "
          />
        </foreignObject>
        <rect
          x="3.88"
          y="117.79"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="3.88"
          y="111.78"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="149.72"
          y="117.79"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="149.73"
          y="111.77"
          width="2.55"
          height="3.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <!-- <rect
      #rueda3
      x="6.72"
      y="88.03"
      width="18.62"
      height="57.02"
      rx="2.3"
      fill="#646463"
      stroke="#1d1d1b"
      stroke-miterlimit="10"
    /> -->
        <foreignObject
          x="6.72"
          y="88.03"
          width="18.62"
          height="57.02"
          rx="2.3"
          stroke-miterlimit="10"
        >
          <app-wheel-element
            [rueda]="getRueda(0, 3)"
            [mode]="mode"
            (clickRueda)="
              selectedPosicion = { eje: 0, posicion: 3 };
              clickRueda.emit({ rueda: $event, eje: 0, posicion: 3 })
            "
            [isSelected]="
              (selectedPosicion &&
                selectedPosicion.eje === 0 &&
                selectedPosicion.posicion === 3) ??
              false
            "
          />
        </foreignObject>
        <!-- <rect
      x="130.54"
      y="88.03"
      width="18.62"
      height="57.02"
      rx="2.3"
      fill="#646463"
      stroke="#1d1d1b"
      stroke-miterlimit="10"
    /> -->
        <foreignObject
          x="130.54"
          y="88.03"
          width="18.62"
          height="57.02"
          rx="2.3"
          stroke-miterlimit="10"
        >
          <app-wheel-element
            [rueda]="getRueda(0, 4)"
            [mode]="mode"
            (clickRueda)="
              selectedPosicion = { eje: 0, posicion: 4 };
              clickRueda.emit({ rueda: $event, eje: 0, posicion: 4 })
            "
            [isSelected]="
              (selectedPosicion &&
                selectedPosicion.eje === 0 &&
                selectedPosicion.posicion === 4) ??
              false
            "
          />
        </foreignObject>
        <rect
          x="60.48"
          y="71.53"
          width="34.55"
          height="28.27"
          rx="2.44"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <rect
          x="49.85"
          y="42.07"
          width="55.24"
          height="95.8"
          rx="2.02"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="42.95"
          y="42.17"
          width="69.61"
          height="103.35"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <rect
          x="51.33"
          y="122.19"
          width="51.88"
          height="3.17"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="74.28"
          y="100.46"
          width="6.35"
          height="21.7"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="74.24"
          y="125.42"
          width="6.45"
          height="9.67"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="63.53"
          y="135.07"
          width="27.81"
          height="2.8"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="44.07"
          y="89.56"
          width="68.49"
          height="1.6"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="106.78"
          y="63.97"
          width="3.17"
          height="67.18"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="45.56"
          y="63.03"
          width="2.43"
          height="67.18"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="66.68"
          y="148.2"
          width="19.94"
          height="15.98"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="64.44"
          y="164.19"
          width="24.08"
          height="4.29"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.68"
          y="171.46"
          width="30.78"
          height="5.6"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="86.63"
          y1="159.14"
          x2="66.69"
          y2="159.14"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="86.35"
          y1="168.48"
          x2="86.35"
          y2="171.46"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="66.69"
          y1="168.48"
          x2="66.69"
          y2="171.46"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polygon
          points="83.7 204.73 71.47 204.73 71.47 208.8 73.43 208.8 73.43 210.33 71.94 210.33 71.94 217.72 73.52 217.72 73.52 218.77 81.55 218.77 81.55 217.82 83.7 217.82 83.7 210.99 82.39 210.99 82.39 208.91 83.7 208.91 83.7 204.73"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M49.85,202.85"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <polyline
          points="82.89 177.1 82.89 183.17 81.02 183.17 81.02 185.39 73.93 185.39 73.93 183.07 72.06 183.07 72.06 177.1"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="80.63"
          y1="185.51"
          x2="80.63"
          y2="203.82"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="74.31"
          y1="185.51"
          x2="74.31"
          y2="203.82"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="92.19 219.04 92.19 147.55 96.86 147.55 96.86 219.04"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="57.01 219.01 57.01 147.55 61.68 147.55 61.68 219.01"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="81.16"
          y1="183.13"
          x2="74.07"
          y2="183.13"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M130.61,118c7.09-.37,9-5.4,9-5.4V15.72C136.82-1.78,118.72.6,118.72.6h-83s-18.15-2.38-21,15.12v96a2.38,2.38,0,0,0,.62,1.61,14.81,14.81,0,0,0,10.1,4.74"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M141.14,52.5a22.34,22.34,0,0,1,6.72,1.68c3.36,1.49,6.53,4.48,6,6.72s-4.84-1.63-9.52-2.24l-4.66-.56V52.5Z"
          fill="#fff"
          stroke="#000"
          stroke-miterlimit="10"
        />
        <path
          d="M130.38,116.38c6.5-1.84,6.39-4.88,6.39-4.88V19.14C134.14,2.41,116.83,4.72,116.83,4.72H37.6S20.33,2.41,17.66,19.14v92.41s1.07,4.62,7.84,5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M128.91,146.89c6.77-.34,7.84-4.67,7.84-4.67V55.67C134.08,40,116.81,42.16,116.81,42.16H37.58S20.31,40,17.64,55.67v86.55s1.07,4.33,7.83,4.67c6.25.31,45.69,0,51.72,0S122.67,147.2,128.91,146.89Z"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M13.27,52.5a22.28,22.28,0,0,0-6.71,1.68C3.2,55.71,0,58.7.56,60.94s4.88-1.63,9.58-2.21l4.67-.56V52.5Z"
          fill="#fff"
          stroke="#000"
          stroke-miterlimit="10"
        />
        <path
          d="M119.67,4.7V7.58s5.91-.87,14.24,4.05"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <polyline
          points="109.2 0.5 109.2 2.74 94.27 2.74 94.27 0.39"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M35.08,4.7V7.58s-5.94-.87-14.24,4.05"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <polyline
          points="45.55 0.5 45.55 2.74 60.48 2.74 60.48 0.39"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="102.49"
          y="144.64"
          width="33.68"
          height="30.39"
          rx="1.89"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="21.74"
          y="144.62"
          width="33.68"
          height="30.39"
          rx="1.89"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <line
          x1="24.54"
          y1="175.01"
          x2="24.54"
          y2="147.2"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="61.68"
          y="179.41"
          width="30.53"
          height="6.36"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="87.44"
          y="201.86"
          width="4.76"
          height="5.78"
          rx="0.85"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.68"
          y="201.86"
          width="4.76"
          height="5.78"
          rx="0.85"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="66.44"
          y="203.83"
          width="21"
          height="2.41"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          x1="81.42"
          y1="217.87"
          x2="73.65"
          y2="217.87"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M105.32,202.55"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="102.36"
          y="144.58"
          width="33.68"
          height="30.39"
          rx="1.89"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <line
          x1="132.7"
          y1="175.03"
          x2="132.7"
          y2="148.25"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M105.19,202.5"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
      </svg>
    </div>

    <!-- cabeza de remolque -->
  } @else if (!isTractora && !isVehiculoGenerico) {
    <div class="flex flex-column">
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 155.1 170.12"
      >
        <polyline
          points="92.51 169.81 92.51 72.06 97.18 72.06 97.18 169.81"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="57.33 169.93 57.33 72.2 62 72.2 62 169.93"
          fill="#fff"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M121.08,129.93"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <path
          d="M120.9,129.87"
          fill="none"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        />
        <rect
          x="26.83"
          y="167.17"
          width="94.41"
          height="2.7"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="10.82"
          y="113.77"
          width="134.52"
          height="3.63"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="10.82"
          y="127.51"
          width="134.52"
          height="3.63"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polygon
          points="123.44 6.93 40.61 6.93 29.66 6.93 29.66 1.08 123.44 1.08 123.44 6.93"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polygon
          points="57 24.16 40.61 6.93 43.6 6.93 57 21.16 57 24.16"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polygon
          points="96.8 23.68 113.19 6.93 110.19 6.93 96.8 20.75 96.8 23.68"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="66.93"
          y="6.93"
          width="4.73"
          height="24.09"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="81.5"
          y="6.93"
          width="4.73"
          height="24.09"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.57"
          y="31.02"
          width="30.66"
          height="2.92"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="61.57"
          y="60.71"
          width="30.66"
          height="2.13"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="53.77"
          y="71.13"
          width="2.76"
          height="4.82"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="96.8"
          y="71.61"
          width="2.61"
          height="4.34"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="71.66"
          y="14.35"
          width="9.85"
          height="3.79"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <ellipse cx="147.58" cy="129.25" rx="4.57" ry="4.26" fill="#1d1e1c" />
        <rect
          x="133.82"
          y="117.8"
          width="8.75"
          height="9.52"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="144.43"
          y1="113.77"
          x2="97.04"
          y2="108.96"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="143.67"
          y1="131.07"
          x2="96.74"
          y2="136.13"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <polyline
          points="57.23 168.57 1.5 168.59 1.5 35.67 8.8 32.05"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <polyline
          points="96.99 168.49 153.6 168.49 153.6 35.67 146.04 31.96"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <ellipse
          cx="33.91"
          cy="4.01"
          rx="1.42"
          ry="1.03"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <ellipse
          cx="119.34"
          cy="3.93"
          rx="1.42"
          ry="1.03"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <ellipse cx="147.95" cy="115.21" rx="4.25" ry="4.26" fill="#1d1e1c" />
        <ellipse cx="7.49" cy="115.74" rx="4.57" ry="4.26" fill="#1d1e1c" />
        <rect
          x="12.51"
          y="117.67"
          width="8.75"
          height="9.52"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="10.65"
          y1="131.22"
          x2="56.84"
          y2="136.03"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="11.39"
          y1="113.93"
          x2="56.84"
          y2="108.87"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <ellipse cx="7.13" cy="129.79" rx="4.25" ry="4.26" fill="#1d1e1c" />
        <rect
          x="10.76"
          y="53.06"
          width="134.52"
          height="3.63"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <rect
          x="10.76"
          y="66.8"
          width="134.52"
          height="3.63"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <ellipse cx="147.52" cy="68.54" rx="4.57" ry="4.26" fill="#1d1e1c" />
        <rect
          x="133.76"
          y="57.09"
          width="8.75"
          height="9.52"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="144.37"
          y1="53.06"
          x2="96.98"
          y2="48.24"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="143.6"
          y1="70.35"
          x2="96.67"
          y2="75.41"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <ellipse cx="147.88" cy="54.49" rx="4.25" ry="4.26" fill="#1d1e1c" />
        <ellipse cx="7.43" cy="55.03" rx="4.57" ry="4.26" fill="#1d1e1c" />
        <rect
          x="12.44"
          y="56.96"
          width="8.75"
          height="9.52"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="10.58"
          y1="70.51"
          x2="56.77"
          y2="75.32"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <line
          x1="11.32"
          y1="53.21"
          x2="56.77"
          y2="48.15"
          fill="none"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
        />
        <ellipse cx="7.07" cy="69.07" rx="4.25" ry="4.26" fill="#1d1e1c" />
        <polyline
          points="57.28 170.04 57.28 0.36 61.85 0.36 61.92 170.06"
          fill="#fff"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <polyline
          points="92.43 169.97 92.51 0.25 97.08 0.25 97.11 170.05"
          fill="#fff"
          stroke="#1d1e1c"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
      </svg>
    </div>
  }

  <!-- Ejes -->
  @for (eje of ejes; track $index) {
    <!-- Eje tipo remolque -->
    @if (eje.tipo === 'r') {
      <div class="flex flex-column">
        <svg
          id="Capa_1"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 154.24 79.11"
        >
          <rect
            x="0.25"
            y="34.33"
            width="153.74"
            height="7.08"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect x="92.06" width="4.64" height="79.11" fill="#fff" />
          <rect x="56.82" width="4.64" height="79.11" fill="#fff" />
          <rect
            x="96.56"
            y="29.49"
            width="5.63"
            height="16.38"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="102.21"
            y="31.37"
            width="3.4"
            height="12.73"
            rx="0.72"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="51.06"
            y="29.49"
            width="5.73"
            height="16.38"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="47.64"
            y="31.37"
            width="3.4"
            height="12.73"
            rx="0.72"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="91.97"
            y1="79.1"
            x2="91.97"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="96.63"
            x2="96.63"
            y2="79.1"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="56.85"
            y1="79.1"
            x2="56.85"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="61.51"
            x2="61.51"
            y2="79.1"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <!-- <rect
          x="134.07"
          y="9.75"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="134.07"
            y="9.75"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 4)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 4 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 4 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 4) ??
                false
              "
            />
          </foreignObject>
          <!--  <rect
          x="111.95"
          y="9.75"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="111.95"
            y="9.75"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 3)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 3 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 3 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 3) ??
                false
              "
            />
          </foreignObject>
          <!-- <rect
          x="23.47"
          y="9.75"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="23.47"
            y="9.75"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 2)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 2 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 2 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 2) ??
                false
              "
            />
          </foreignObject>
          <!--  <rect
          x="1.35"
          y="9.75"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="1.35"
            y="9.75"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 1)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 1 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 1 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 1) ??
                false
              "
            />
          </foreignObject>
        </svg>
      </div>
    }
    <!-- Eje tipo motriz 2 ruedas -->
    @if (eje.tipo === 'm' && eje.n_ruedas === 2) {
      <div class="flex flex-column">
        <svg
          id="Capa_1"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 154.24 79.13"
        >
          <rect
            x="0.25"
            y="34.35"
            width="153.74"
            height="7.08"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect x="92.06" y="0.02" width="4.64" height="79.11" fill="#fff" />
          <rect x="56.82" y="0.02" width="4.64" height="79.11" fill="#fff" />
          <rect x="74.11" width="6.44" height="79.12" fill="#9c9b9b" />
          <line
            x1="74.1"
            y1="79.1"
            x2="74.1"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="80.45"
            x2="80.45"
            y2="79.1"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="96.56"
            y="29.51"
            width="5.63"
            height="16.38"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="102.21"
            y="31.39"
            width="3.4"
            height="12.73"
            rx="0.72"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="51.06"
            y="29.51"
            width="5.73"
            height="16.38"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="47.64"
            y="31.39"
            width="3.4"
            height="12.73"
            rx="0.72"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="91.97"
            y1="79.12"
            x2="91.97"
            y2="0.02"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="96.63"
            y1="0.02"
            x2="96.63"
            y2="79.12"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="56.85"
            y1="79.12"
            x2="56.85"
            y2="0.02"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="61.51"
            y1="0.02"
            x2="61.51"
            y2="79.12"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="72.21"
            y="33.44"
            width="10.53"
            height="2.75"
            fill="#fff"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <polygon
            points="73.75 36.19 73.75 42.35 68.79 44.09 68.79 46.41 86.16 46.41 86.16 43.91 81.04 41.88 81.04 36.19 73.75 36.19"
            fill="#fff"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <!--   <rect
          x="134.07"
          y="9.77"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="134.07"
            y="9.77"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 2)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 2 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 2 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 2) ??
                false
              "
            />
          </foreignObject>
          <!-- <rect
          x="1.35"
          y="9.77"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="1.35"
            y="9.77"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 1)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 1 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 1 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 1) ??
                false
              "
            />
          </foreignObject>
        </svg>
      </div>
    }
    <!-- Eje tipo motriz 4 ruedas -->
    @if (eje.tipo === 'm' && eje.n_ruedas === 4) {
      <div class="flex flex-column">
        <svg
          id="Capa_1"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 154.24 79.13"
        >
          <rect
            x="0.25"
            y="34.35"
            width="153.74"
            height="7.08"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect x="92.06" y="0.02" width="4.64" height="79.11" fill="#fff" />
          <rect x="56.82" y="0.02" width="4.64" height="79.11" fill="#fff" />
          <rect x="74.11" width="6.44" height="79.12" fill="#9c9b9b" />
          <line
            x1="74.1"
            y1="79.1"
            x2="74.1"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="80.45"
            x2="80.45"
            y2="79.1"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="96.56"
            y="29.51"
            width="5.63"
            height="16.38"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="102.21"
            y="31.39"
            width="3.4"
            height="12.73"
            rx="0.72"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="51.06"
            y="29.51"
            width="5.73"
            height="16.38"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="47.64"
            y="31.39"
            width="3.4"
            height="12.73"
            rx="0.72"
            fill="#9c9b9b"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="91.97"
            y1="79.12"
            x2="91.97"
            y2="0.02"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="96.63"
            y1="0.02"
            x2="96.63"
            y2="79.12"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="56.85"
            y1="79.12"
            x2="56.85"
            y2="0.02"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <line
            x1="61.51"
            y1="0.02"
            x2="61.51"
            y2="79.12"
            fill="none"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <rect
            x="72.21"
            y="33.44"
            width="10.53"
            height="2.75"
            fill="#fff"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <polygon
            points="73.75 36.19 73.75 42.35 68.79 44.09 68.79 46.41 86.16 46.41 86.16 43.91 81.04 41.88 81.04 36.19 73.75 36.19"
            fill="#fff"
            stroke="#1d1d1b"
            stroke-miterlimit="10"
            stroke-width="0.5"
          />
          <!--      <rect
          x="134.07"
          y="9.77"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="134.07"
            y="9.77"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 4)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 4 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 4 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 4) ??
                false
              "
            />
          </foreignObject>
          <!-- <rect
          x="111.95"
          y="9.77"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="111.95"
            y="9.77"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 3)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 3 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 3 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 3) ??
                false
              "
            />
          </foreignObject>
          <!--  <rect
          x="23.47"
          y="9.77"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="23.47"
            y="9.77"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 2)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 2 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 2 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 2) ??
                false
              "
            />
          </foreignObject>
          <!--  <rect
          x="1.35"
          y="9.77"
          width="19.03"
          height="56.23"
          rx="2.3"
          fill="#646463"
          stroke="#1d1d1b"
          stroke-miterlimit="10"
        /> -->
          <foreignObject
            x="1.35"
            y="9.77"
            width="19.03"
            height="56.23"
            rx="2.3"
            stroke-miterlimit="10"
          >
            <app-wheel-element
              [rueda]="getRueda($index, 1)"
              [mode]="mode"
              (clickRueda)="
                selectedPosicion = { eje: $index, posicion: 1 };
                clickRueda.emit({ rueda: $event, eje: $index, posicion: 1 })
              "
              [isSelected]="
                (selectedPosicion &&
                  selectedPosicion.eje === $index &&
                  selectedPosicion.posicion === 1) ??
                false
              "
            />
          </foreignObject>
        </svg>
      </div>
    }
  }
}
