import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core'

import { EntityInterfaces } from '@goplanner/api-client'

import { WheelElementComponent } from './wheel-element/wheel-element.component'

interface Eje {
  tipo: 'd' | 'm' | 'r'
  n_ruedas: 2 | 4
}

@Component({
  selector: 'app-axis-visualizer',
  standalone: true,
  imports: [WheelElementComponent],
  templateUrl: 'axis-visualizer.component.html'
})
export class AxisVisualizerComponent implements OnChanges {
  @Input()
  calculateEjes?: () => void

  @Input()
  isTractora = true

  @Input()
  isVehiculoGenerico = true

  @Input()
  ejes: Eje[] = []

  @Input()
  ruedas: EntityInterfaces['veh_cubiertas']['get'][] = []

  // carga en componente en modo wheel (interactivo) o axis (solo visual)
  @Input()
  mode: 'wheels' | 'axis' = 'wheels'

  @Output()
  clickRueda = new EventEmitter<{
    eje: number
    posicion: number
    rueda: EntityInterfaces['veh_cubiertas']['get'] | null
  }>()

  // recibe la rueda seleccionada que puede ser nueva
  @Input()
  selectedRueda: EntityInterfaces['veh_cubiertas']['get'] | null = null

  selectedPosicion: {
    eje: number
    posicion: number
  } | null = null

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedRueda']) {
      this.selectedPosicion = this.getPosicion()
    }
    // llamada al metodo
    if (this.calculateEjes) {
      this.calculateEjes()
    }
  }

  getPosicion() {
    if (!this.selectedRueda) return null
    let posicion_rueda = this.selectedRueda.posiocion_respecto_eje
    return this.ejes.reduce(
      (
        acc: {
          eje: number
          posicion: number
        } | null,
        e,
        i
      ) => {
        if (posicion_rueda < e.n_ruedas && posicion_rueda > 0) {
          acc = {
            eje: i - 1,
            posicion: posicion_rueda
          }
          return acc
        } else if (posicion_rueda > 0) {
          posicion_rueda -= e.n_ruedas
          return acc
        }
        return acc
      },
      null
    )
  }

  getPosicionRespectoEje(index: number, posicion: number) {
    return (
      this.ejes.reduce((acc, e, i) => (i < index ? acc + e.n_ruedas : acc), 0) +
      posicion
    )
  }

  getRueda(index: number, posicion_eje: number) {
    // Encontramos el inicio del eje actual
    // y le sumamos la posición de la rueda en el mismo para saber la posición absoluta
    if (!this.ruedas || !this.ejes) return null
    return (
      this.ruedas.find(
        (r) =>
          r.posiocion_respecto_eje ===
          this.getPosicionRespectoEje(index, posicion_eje)
      ) || null
    )
  }
}
